<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Endereços"
        p-read-link="address.read.one"
        p-create-link="address.create"
        p-name="address"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwAddressRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Endereço',
                value: 'endereco'
            }, {
                text: 'Bairro',
                value: 'bairro'
            }, {
                text: 'Cep',
                value: 'cep'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>